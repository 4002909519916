import React, { useContext, useEffect, useState } from "react"
import { Button, Card } from "@mui/material"
import { Download as DownloadIcon } from "@mui/icons-material"
import dayjs from "dayjs"

import SelectField from "../components/items/SelectField"
import StackBar from "../components/charts/StackBar"
import { appService } from "../api/services"
import { ErrorContext } from "../helper/AlertContext"

import DashboardStyles from "../styles/Dashboard.module.css"

const DATE_RANGE_OPTIONS = [
  { label: "1 Day", value: 1 },
  { label: "3 Days", value: 3 },
  { label: "7 Days", value: 7 },
  { label: "30 Days", value: 30 },
]
const INTERVAL_MAPPING = {
  day: 86400,
  hour: 3600,
}

const Charts = () => {
  const [chartXAxisLabels, setChartXAxisLabels] = useState([])
  const [dateRange, setDateRange] = useState(DATE_RANGE_OPTIONS[3].value)
  const [projectList, setProjectList] = useState([])
  const [selectedProject, setSelectedProject] = useState("all")
  const [usageData, setUsageData] = useState([])

  const { setError, setErrorMsg } = useContext(ErrorContext)

  const downloadUsageData = async () => {
    const startDate = dayjs()
      .subtract(dateRange - 1, "day")
      .format("YYYYMMDD")
    const endDate = dayjs().format("YYYYMMDD")
    const appName = projectList.find((app) => app.value === selectedProject)?.label || "All"

    try {
      const { data } = await appService.downloadUsage({
        start_date: startDate,
        end_date: endDate,
        interval: dateRange > 3 ? INTERVAL_MAPPING["day"] : INTERVAL_MAPPING["hour"],
        id: selectedProject === "all" ? undefined : selectedProject,
      })
      const file = new Blob([data], { type: "text/csv" })
      const fileHref = document.createElement("a")

      fileHref.href = URL.createObjectURL(file)
      fileHref.setAttribute("download", `${appName}_${startDate}_${endDate}.csv`)
      document.body.appendChild(fileHref)
      fileHref.click()
      fileHref.parentNode.removeChild(fileHref)
    } catch (error) {
      setError(true)
      setErrorMsg("Can't download usage data.")
    }
  }
  const fetchProjectList = async () => {
    try {
      const { data } = await appService.getProjectList({ all: true })
      setProjectList(data.map((item) => ({ label: item.name, value: item.id })))
    } catch (error) {
      setError(true)
      setErrorMsg("Can't fetch project list.")
    }
  }
  const fetchUsageData = async () => {
    try {
      const { data } = await appService.getUsageByApp({
        start_date: dayjs()
          .subtract(dateRange - 1, "day")
          .format("YYYYMMDD"),
        end_date: dayjs().format("YYYYMMDD"),
        interval: dateRange > 3 ? INTERVAL_MAPPING["day"] : INTERVAL_MAPPING["hour"],
        id: selectedProject === "all" ? undefined : selectedProject,
      })

      if (data.length) {
        const dateFormat = dateRange > 3 ? "DD MMM" : "DD MMM HH:mm"
        const xLabels = data[0].labels.map((label) => dayjs(label * 1000).format(dateFormat))

        setChartXAxisLabels(xLabels)
        setUsageData(
          Array.from({ length: data.length }, (_, index) => {
            return {
              label: data[index].name,
              data: data[index].datasets.credits,
            }
          }),
        )
      }
    } catch (error) {
      setError(true)
      setErrorMsg("Can't fetch usage data.")
    }
  }

  useEffect(() => {
    fetchUsageData()
  }, [dateRange, selectedProject])
  useEffect(() => {
    fetchProjectList()
  }, [])

  return (
    <Card className={DashboardStyles.usageCard}>
      <h3>Usage by Project</h3>
      <div style={{ display: "flex", justifyContent: "flex-end", gap: "0.5rem" }}>
        <SelectField
          value={dateRange}
          onChange={(event) => setDateRange(event.target.value)}
          options={DATE_RANGE_OPTIONS}
          divStyle={{ width: 120 }}
        />
        <Button
          variant="outlined"
          sx={{ maxWidth: "fit-content", minWidth: "fit-content", p: "0.5rem" }}
          onClick={() => {
            downloadUsageData()
          }}
        >
          <DownloadIcon />
        </Button>
      </div>
      <div>
        <StackBar chartDataset={usageData} xAxisLabels={chartXAxisLabels} />
      </div>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <h4 style={{ margin: 0 }}>Project</h4>
        <SelectField
          value={selectedProject}
          onChange={(event) => {
            if (event.target.value) {
              setSelectedProject(event.target.value)
            }
          }}
          options={[{ label: "Show all", value: "all" }, ...projectList]}
          divStyle={{ width: "40%" }}
        />
      </div>
    </Card>
  )
}

export default Charts
